<template>
  <div class="c-scheduleSpCollapse">
    <div
      class="c-scheduleSpCollapse_text"
      :class="{ 'c-scheduleSpCollapse_text-ellipsis': !freeTextExpanded }"
      v-text="freeText"
    ></div>
    <div
      class="c-scheduleSpCollapse_readMore"
      v-if="freeTextNeedReadMore && !freeTextExpanded"
    >
      <a @click="freeTextExpanded = true">
        <coconala-icon
          name="chevron-down"
          size="11px"
        />
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ScheduleSpCollapse',
  props: {
    freeText: {
      type: String,
      defalut: ''
    },
    expansion: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      freeTextExpanded: false,
      freeTextNeedReadMore: true
    }
  }
}
</script>
<style lang="scss" scoped>
.c-scheduleSpCollapse {
  display: flex;
  justify-content: space-between;

  &_text {
    flex: 1 1 auto;
    white-space: pre-wrap;
    word-wrap: break-word;

    &-ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &_readMore {
    flex: 0 0 20px;
    color: $ds2-color-brand-secondary-700;
    font-size: 13px;

    a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .-chevron-down {
      position: absolute;
      bottom: 11px;
      right: 12px;
    }
  }
}
</style>
